import { ExpandMore, MeetingRoom} from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, SelectChangeEvent, Typography } from "@mui/material";
import { Col, Container, Row } from "reactstrap";
import HotelIcon from '@mui/icons-material/Hotel';
import { grey } from "@mui/material/colors";
import './style.css';
import { NoMaxWidthTooltip } from "../../../../components/Tooltip/NoMaxWidthToolTip";
import { substringToChars } from "../../../../util/string-helpers";
import TopDrawer from "../../../../components/TopDrawer/TopDrawer";
import { useState } from "react";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import { isNotNullOrEmpty } from "../../../../util/null-helper";
import { RESERVATION_STATUS } from "../../../../variables/reservations";
import { AxiosResponse } from "axios";
import { convertTo13DigitsTimeStamp } from "../../../../util/time-helpers";
import moment from "moment";
import SecondaryLoader from "../../../../components/Loader/SecondaryLoader";
import DrawerContent from "./DrawerContent";

type CheckInsTableProps = {
    partnerId: string,
    role: string,
    checkIns: Array<any>,
    showPartnerName: boolean,
    partnerName: string,
    showEmptyContent: boolean,
    selectFilterMessage: any,
    sortUnitsBy: string,
    isLoading: boolean,
    resultSectionOpen: boolean,
    onResultSectionOpenChange: (open: boolean) => void,
    onLoadReservations: (
        partnerId: string, 
        status: string,
        tuyaUnitId: string,
        pageSize: number,
        pageNumber: number,
        checkInFromTimeStamp?: number,
        sortBy?: string) => any,
    onReservationClickSort: (
        status: string, 
        tuyaUnitId: string,
        pageSize: number,
        pageNumber: number,
        checkInFromTimeStamp: number,
        sortBy: string) => Promise<AxiosResponse<any>>,
    onReservationPageSizeChange: (
        e: SelectChangeEvent, 
        tuyaUnitId: string, 
        status: string, 
        checkInFromTimeStamp: number, 
        sortBy: string) => Promise<AxiosResponse<any>>,
    onReservationPageChange: (
        tuyaUnitId: string, 
        status: string, 
        pageSize: number, 
        pageNumber: number, 
        checkInFromTimeStamp: number, 
        sortBy: string) => Promise<AxiosResponse<any>>
}

const UPCOMING_PAGE_SIZE = 5;

export default function CheckInsTable({ 
        partnerId,
        role,
        checkIns, 
        showPartnerName, 
        partnerName, 
        showEmptyContent, 
        selectFilterMessage,
        isLoading,
        sortUnitsBy,
        resultSectionOpen,
        onResultSectionOpenChange,
        onLoadReservations,
        onReservationClickSort,
        onReservationPageSizeChange,
        onReservationPageChange
}: CheckInsTableProps){

    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerCurrentTab, setDrawerCurrentTab] = useState('actual');
    const [loadingCheckInDetailsForUnitId, setLoadingCheckInDetailsForUnitId] = useState<string>(null);
    const [actualReservationsResult, setActualReservationsResult] = useState(null);
    const [upcomingReservationsResult, setUpcomingReservationsResult] = useState(null);
    const [reservationsPageSize, setReservationsPageSize] = useState(UPCOMING_PAGE_SIZE);
    const [reservationsPageNumber, setReservationsPageNumber] = useState(1);
    const [reservationsTuyaUnitId, setReservationsTuyaUnitId] = useState('');
    const [integrationType, setIntegrationType] = useState('');
    const [reservationsSortBy, setReservationsSortBy] = useState('-ArrivalTimestamp');

    const fromCheckInTimeStamp = convertTo13DigitsTimeStamp(moment().unix());

    const handleCloseDrawer = () => {
        setDrawerTitle('');
        setActualReservationsResult(null);
        setUpcomingReservationsResult(null);
        setReservationsPageSize(UPCOMING_PAGE_SIZE);
        setReservationsPageNumber(1);
        setIsDrawerOpen(false);
        setDrawerCurrentTab('actual');
        setIntegrationType('');
    }

    const handleReservationClickSort = async (
            direction: string, 
            column: string) => {

        const newSort = `${direction}${column}`;
        setReservationsSortBy(newSort)
        var result = await onReservationClickSort(
            RESERVATION_STATUS.PendingCheckIn, 
            reservationsTuyaUnitId, 
            reservationsPageSize, 
            reservationsPageNumber, 
            fromCheckInTimeStamp, 
            newSort);

        setUpcomingReservationsResult(result);
    }

    const handleReservationPageSizeChange = async (e: SelectChangeEvent) => {
        setReservationsPageSize(parseInt(e.target.value));
        var result = await onReservationPageSizeChange(
            e, 
            reservationsTuyaUnitId, 
            RESERVATION_STATUS.PendingCheckIn, 
            fromCheckInTimeStamp, 
            reservationsSortBy);

        setUpcomingReservationsResult(result);
    }

    const handleReservationPageChange = async (e: SelectChangeEvent, newPage: number) => {
        setReservationsPageNumber(newPage);
        var result = await onReservationPageChange(
            reservationsTuyaUnitId, 
            RESERVATION_STATUS.PendingCheckIn, 
            reservationsPageSize, 
            newPage, 
            fromCheckInTimeStamp, 
            reservationsSortBy);

        setUpcomingReservationsResult(result);
    }

    const getToolTipTitle = (moveIn: any, occupied: boolean) => {
        return <Container fluid>
            <div>
                <div className="d-flex mb-1">
                    <div>{moveIn.unitName}</div>
                </div>
                {occupied === true ? <div className="d-flex">
                    <div>Guest -&nbsp;</div>
                    <div>{moveIn.guestName}</div>
                </div> : null}
                {occupied === true ? <div className="d-flex">
                    <div>Reservation -&nbsp;</div>
                    <div>{moveIn.externalReservationId}</div>
                </div> : null}
            </div>
        </Container>;
    }

    const MoveInCard = ({ moveIn, children }: {moveIn: any, children: any}) => {
        return <NoMaxWidthTooltip title={getToolTipTitle(moveIn, moveIn.occupied)} >
            {children}
        </NoMaxWidthTooltip>;
    }

    const handleCardClick = async (moveIn: any) => {
        if(isNotNullOrEmpty(moveIn.unitInternalId)) {
            setLoadingCheckInDetailsForUnitId(moveIn.unitInternalId);

            var actualsPromise = onLoadReservations(partnerId,
                RESERVATION_STATUS.CheckedIn,
                moveIn.unitInternalId,
                1, 
                1);

            var upcomingPromise = onLoadReservations(partnerId,
                RESERVATION_STATUS.PendingCheckIn,
                moveIn.unitInternalId,
                reservationsPageSize, 
                1,
                fromCheckInTimeStamp);
            
            const [actuals, upcoming] = await Promise.all([actualsPromise, upcomingPromise]);

            setActualReservationsResult(actuals);
            setUpcomingReservationsResult(upcoming);
            setReservationsTuyaUnitId(moveIn.unitInternalId);
            setDrawerTitle(moveIn.unitName);
            setIntegrationType(moveIn.integrationType);
            setLoadingCheckInDetailsForUnitId(null);
            setIsDrawerOpen(true);
        }
    }

    return <>
        <Accordion expanded={resultSectionOpen} onChange={() => onResultSectionOpenChange(!resultSectionOpen)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="settings-content"
                id="settings-header"
            >
                <div style={{ width: '100%'}}>
                    <Row>
                        <Col lg={12}>
                            <span className="h2 mb-3">{showPartnerName ? partnerName : "Check-Ins"}</span>
                        </Col>
                        {showEmptyContent === false ? <Col lg={3}>
                            ({checkIns ? checkIns.length : 0}) Items
                        </Col> : null}
                    </Row>
                </div>
            </AccordionSummary>
            <AccordionDetails style={{ height: '100%'}}>
                <Container fluid className="pb-5">
                    {showEmptyContent === true ? <Row>
                        <Col lg={12} className="d-flex justify-content-center">
                            <div className="mt-5 mb-5">
                                {selectFilterMessage}
                            </div>
                        </Col> 
                    </Row> : null}
                    {checkIns && checkIns.length > 0 ? <>
                        {showEmptyContent === false && resultSectionOpen === true ? <Row className="mb-1">
                            <Col lg={4}>
                            </Col>
                            <Col lg={3}>
                            </Col>
                        </Row> : null}
                        <Row>
                            {checkIns.map((m, i) => {
                                return <Col lg={2} className="mb-3 property">
                                    <MoveInCard moveIn={m} >
                                        <Card style={{ backgroundColor: grey[100], minHeight: 80 }} onClick={() => handleCardClick(m)}>
                                            <div className="d-flex">
                                                <div 
                                                    style={{ 
                                                        height: "80px", 
                                                        width: '10px', 
                                                        backgroundColor: m.occupied === true ? EasyHubPrimaryColor : grey[400]}
                                                    }
                                                >
                                                    &nbsp;
                                                </div>
                                                <div className="ml-2" style={{ position: 'relative' } }>
                                                    <div className="mt-2">
                                                        <Typography>
                                                            {substringToChars(m.unitName, 30)}
                                                        </Typography> 
                                                    </div>
                                                    {m.occupied === true ? <div style={{ position: 'absolute', bottom: '5px' }}>
                                                        <HotelIcon 
                                                            sx={{ fontSize: "14px" , color: EasyHubPrimaryColor }}
                                                        />
                                                    </div> : null}    
                                                    {m.occupied === false ? <div style={{ position: 'absolute', bottom: '5px' }}>
                                                        <MeetingRoom 
                                                            sx={{ fontSize: "14px" , color:  grey[400] }}
                                                        />
                                                    </div> : null}
                                                </div>
                                                {loadingCheckInDetailsForUnitId === m.unitInternalId ? <div style={{ width: '100%', position: 'absolute', bottom: '2px', right: '18px'}}>
                                                    <div style={{ float: 'right'}}>
                                                        <SecondaryLoader width={20} color={m.occupied === true ? EasyHubPrimaryColor : grey[400]} />
                                                    </div>
                                                </div> : null}
                                            </div>
                                        </Card>
                                    </MoveInCard>
                                </Col>
                            })}
                        </Row>
                    </> : null}
                </Container>
                <TopDrawer
                    title={drawerTitle}
                    isOpen={isDrawerOpen}
                    onOpen={(open: boolean) => handleCloseDrawer()}
                >
                    <DrawerContent 
                        actuals={actualReservationsResult}
                        upcoming={upcomingReservationsResult}
                        title={drawerTitle}
                        role={role}
                        tab={drawerCurrentTab}
                        isLoading={isLoading}
                        reservationsPageSize={reservationsPageSize}
                        reservationsPageNumber={reservationsPageNumber}
                        reservationsSortBy={reservationsSortBy}
                        integrationType={integrationType}
                        onClose={handleCloseDrawer}
                        setValue={setDrawerCurrentTab}
                        handleReservationClickSort={handleReservationClickSort}
                        handleReservationPageChange={handleReservationPageChange}
                        handleReservationPageSizeChange={handleReservationPageSizeChange}
                        
                    />
                </TopDrawer>
            </AccordionDetails>
        </Accordion>
    </>
}