import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";


export const useUser = () => {
  const [user, setUser] = useState(null);
  const { setItem } = useLocalStorage();

  const addUser = (user) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    setItem("user", null);
  };

  return { user, addUser, removeUser, setUser };
};
