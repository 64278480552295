import { Box, Button, Chip, SelectChangeEvent, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Col, Container, Row } from "reactstrap";
import { arrayNotNullAndHasEntries, isNotNullOrEmptyShowValueOr } from "../../../../util/null-helper";
import { IsAdmin } from "../../../../util/role-helpers";
import Pagination from "../../components/Pagination";
import ReservationsTable from "../../components/tables/ReservationsTable";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import Loader from "../../../../components/Loader/Loader";
import { INTEGRATION_TYPES } from "../../../../variables/integration-types";


type DrawerContentProps = {
    actuals: any, 
    upcoming: any, 
    title: string,
    role: string,
    tab: string,
    integrationType: string,
    isLoading: boolean,
    reservationsPageSize: number,
    reservationsPageNumber: number,
    reservationsSortBy: string,
    onClose: () => void,
    setValue: (val: string) => void,
    handleReservationClickSort: (direction: string, column: string) => void,
    handleReservationPageChange: (e: SelectChangeEvent, newPage: number) => void,
    handleReservationPageSizeChange: (e: SelectChangeEvent) => void
}

export default function DrawerContent({ 
    actuals, 
    upcoming, 
    title, 
    role, 
    tab,
    isLoading,
    integrationType,
    onClose,
    setValue,
    reservationsPageSize,
    reservationsPageNumber,
    reservationsSortBy,
    handleReservationClickSort,
    handleReservationPageChange,
    handleReservationPageSizeChange
}: DrawerContentProps) {

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const columnSize = { size: 3, offset: 3 };

    return <Container fluid>
        <Row style={{ background: EasyHubPrimaryColor}} className="p-2">
            <Col lg={1} style={{ position: 'relative'}}>
                    <img
                    alt="..."
                    src="https://steasyhubprodus.blob.core.windows.net/public/logo-white.png"
                    width={"125px"}
                    />
            </Col>
            <Col lg={10}>
                {isLoading === true ? <Box position={"absolute"}> <Loader color="#fff" /> </Box> : null}
            </Col>
        </Row>
        <Row className="mb-1">
            <Col lg={12} className="d-flex justify-content-center mb-3 mt-3" style={{ position: 'relative'}}>
                <Box sx={ { position: 'absolute', right: '10px' } }>
                    <Button onClick={onClose} variant="outlined" color="primary">Close</Button>
                </Box>
                <Box>
                    <Box className="d-flex justify-content-center">
                        <Typography variant="h5">
                            {title}
                        </Typography>
                    </Box>
                    <Box className="d-flex justify-content-center">
                        <Typography color={EasyHubPrimaryColor}>
                            (Check-Ins)
                        </Typography>
                    </Box>
                </Box>
            </Col>
            <Col lg={12} className="d-flex justify-content-center mb-3">
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="primary tabs example"
                >
                    <Tab value="actual" label="Actual" />
                    <Tab value="upcoming" label="Upcoming" />
                </Tabs>
            </Col>
            <Col lg={12} className="d-flex justify-content-center">
                {tab === 'actual' ? <>
                    <Container fluid>
                        {actuals && arrayNotNullAndHasEntries(actuals.items) ? actuals.items.map((r, i) => <Row>
                            <Col lg={columnSize} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Reservation Id" 
                                    variant="outlined" 
                                    value={r.externalReservationId} 
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col>
                            <Col lg={3} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Door Lock Code" 
                                    variant="outlined" 
                                    value={r.lockerPassword} 
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col>
                            <Col lg={columnSize} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Arrival" 
                                    variant="outlined" 
                                    value={`${r.arrivalDate} ${r.arrivalDateTime}`}
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col>
                            <Col lg={3} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Departure" 
                                    variant="outlined" 
                                    value={`${r.departureDate} ${r.departureDateTime}`}
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col>
                            <Col lg={columnSize} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Guest" 
                                    variant="outlined" 
                                    value={`${isNotNullOrEmptyShowValueOr(r.contactFirstName, '')} ${isNotNullOrEmptyShowValueOr(r.contactLastName, '')}`}
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col>
                            {IsAdmin(role) ? <Col lg={3} className="d-flex justify-content-center mb-3">
                                <TextField 
                                    id="outlined-basic" 
                                    label="Last Modified On" 
                                    variant="outlined" 
                                    value={`${r.updateDate}`}
                                    aria-disabled
                                    fullWidth
                                    disabled
                                />
                            </Col> : null}
                        </Row>) : null }
                    </Container>
                </> : null}

                {tab === 'upcoming' ? <>
                    <Row>
                        <Col xl={12} className="d-flex justify-content-center mb-3">
                            {upcoming && upcoming.items.length > 0 ? <Pagination 
                                totalPages={upcoming.totalPages}
                                onChange={handleReservationPageChange}
                                page={reservationsPageNumber}
                            /> : null}
                        </Col>
                        <Col lg={12}>
                            {upcoming && upcoming.items.length > 0 ? <ReservationsTable 
                                reservations={upcoming ? upcoming.items : []}
                                handleClickSort={handleReservationClickSort}
                                handlePageSizeChange={handleReservationPageSizeChange}
                                pageSize={reservationsPageSize}
                                sortBy={reservationsSortBy}
                                role={role}
                                isLoading={isLoading}
                                tableLegend={integrationType === INTEGRATION_TYPES.Mapro ? "* Showing reservations for the next 15 days." : null}
                            /> : <Box className="d-flex justify-content-center">
                                <Typography>(0) upcoming check-ins found</Typography>
                            </Box>}
                        </Col>
                        <Col xl={12} className="d-flex justify-content-center mt-3">
                            {upcoming && upcoming.items.length > 0 ? <Pagination 
                                totalPages={upcoming.totalPages}
                                onChange={handleReservationPageChange}
                                page={reservationsPageNumber}
                            /> : null}
                        </Col>
                    </Row>
                </> : null}
            </Col>
        </Row>
        <Row className="mb-5">
            <Col lg={12} className="d-flex justify-content-center mb-4">
                <Box className="mt-3">
                    <Chip label={`Integrated via ${integrationType}`} color="primary" variant="outlined" />
                </Box>
            </Col>
        </Row>
    </Container>
}