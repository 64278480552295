import { useCallback, useEffect, useState } from "react";
import { useUser } from "./useUser";
import { useLocalStorage } from "./useLocalStorage";

export const useAuth = () => {
  const { user, addUser, removeUser, setUser } = useUser();
  const { getItem } = useLocalStorage();
  const [ loaded, setLoaded] = useState(false);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);

  const loadInformationFromStorage = useCallback((user: any) => {
    try {
      if (user) {
        const parsed = JSON.parse(user);
        if(parsed && parsed !== null) {
          setToken(parsed.token);
          setRole(parsed.role);
        }
      }
      setLoaded(true);
    }
    catch {
      removeUser();
    }
  }, [removeUser]);
  

  useEffect(() => {
    const user = getItem("user");
    loadInformationFromStorage(user);
  }, [loadInformationFromStorage, getItem]);

  const login = (user) => {
    addUser(user);
  };

  const logout = () => {
    removeUser();
    setToken(null);
    setRole(null);
  };

  const authenticate = (result) => {
    addUser(result.value);
  };

  return { authenticate, user, token, login, logout, setUser, role, loaded };
};