/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.2.1";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import { createTheme, ThemeProvider } from "@mui/material";
import { blue } from "@mui/material/colors";
import { AppContext } from "./contexts/app-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: "#7686FB",
    },
    secondary: {
      main: blue[500],
    },
  },
});

const App = () => {

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
          <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </BrowserRouter>
  );
};

root.render(<ThemeProvider theme={theme}>
  <AppContext>
    <App />
  </AppContext>
</ThemeProvider>);
