/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/pages/dashboards/Dashboard";
import Login from "./views/pages/auth/Login";
import Clients from "./views/pages/clients/Clients";
import ClientsEdit from "./views/pages/clients/ClientEdit";
import Errors from "./views/pages/errors/Errors";
import Reservations from "./views/pages/reservations/Reservations";
import { ROLES } from "./constants/role";
import CheckIns from "./views/pages/checkins/CheckIns";
import Users from "./views/pages/users/Users";

const routes = [
  {
    collapse: false,
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    path: "/dashboard",
    miniName: "D",
    component: <Dashboard />,
    layout: "/admin",
    views: []
  },
  {
    collapse: false,
    name: "Client Settings",
    icon: "ni ni-building text-primary",
    path: "/client/settings",
    component: <Clients />,
    layout: "/admin",
    views: [],
    visible: false,
    roles: [ROLES.Developer, ROLES.Admin]
  },
  {
    collapse: false,
    name: "Edit Partner",
    icon: "ni ni-building text-primary",
    path: "/client/settings/:id",
    component: <ClientsEdit />,
    layout: "/admin",
    visible: false,
    views: [],
    roles: [ROLES.Developer, ROLES.Admin]
  },
  {
    collapse: false,
    name: "Error Details",
    icon: "ni ni-building text-primary",
    path: "/errors",
    component: <Errors />,
    layout: "/admin",
    visible: false,
    views: []
  },
  {
    collapse: false,
    name: "Reservations",
    icon: "fa fa-ticket text-primary",
    path: "/reservations",
    component: <Reservations />,
    layout: "/admin",
    visible: true,
    views: [],
    roles: [ROLES.Client, ROLES.Developer, ROLES.Admin]
  },
  {
    collapse: false,
    name: "Check-Ins",
    icon: "fa fa-plane-arrival text-primary",
    state: "examplesCollapse",
    path: "/check-ins",
    layout: "/admin",
    visible: true,
    component: <CheckIns />,
    roles: [ROLES.Developer, ROLES.Admin]
  },
  {
    collapse: false,
    name: "Users",
    icon: "fa fa-users text-primary",
    state: "examplesCollapse",
    path: "/users",
    layout: "/admin",
    visible: false,
    component: <Users />,
    roles: [ROLES.Developer, ROLES.Admin]
  },
  {
    collapse: false,
    name: "Login",
    icon: "ni text-orange",
    state: "examplesCollapse",
    path: "/login",
    layout: "/auth",
    visible: false,
    component: <Login />,
  }
];

export default routes;
