import { createContext, Dispatch } from "react";

export type UnitContextProps = {
  unitSearchResult: any,
  setUnitSearchResult: Dispatch<any>
}

export const UnitContext = createContext<UnitContextProps>({
  unitSearchResult: null,
  setUnitSearchResult: () => {},
});