import { shutdown } from "@intercom/messenger-js-sdk";
import { getAccessToken } from "./storage-helpers";
import { AxiosInstance, InternalAxiosRequestConfig, AxiosHeaders, AxiosResponse } from 'axios';

export const createClient = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.request.use(
        (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
            const token = getAccessToken();
            if (token) {
                if (!config.headers) {
                    config.headers = new AxiosHeaders(); // Ensure headers is defined
                }
                config.headers['x-access-token'] = token;
                config.headers['x-functions-key'] = process.env.REACT_APP_CORE_API_FUNCTION_KEY || '';
            }
            return config;
        },
        (error: any): Promise<never> => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => {
            return response;
        },
        (error: any): Promise<never> => {
            if (error.response?.status === 401) {
                localStorage.removeItem('user');  
                shutdown();
                if (window.location.pathname !== '/auth/login') {
                    window.location.href = '/auth/login';
                }
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};