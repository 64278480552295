import { createContext, Dispatch } from "react";

export type ReservationContextType = {
  reservationSearchResult: any,
  setReservationSearchResult: Dispatch<any>
}

export const ReservationContext = createContext<ReservationContextType>({
  reservationSearchResult: null,
  setReservationSearchResult: () => {},
});