import { useState } from "react";
import { AuthContext } from "./auth-context"
import { PartnerContext } from "./partner-context"
import { ReservationContext } from "./reservation-context"
import { UnitContext } from "./unit-context"
import { login } from "../actions/user-actions";

export const AppContext = ({ children }) => {

    const [partnerSearchResult, setPartnerSearchResult] = useState(null);
    const [partners, setPartners] = useState(null);
    const [partner, setPartner] = useState(null);
    const [selectedPartnerId, setSelectedPartnerId] = useState(null);
    const [unitSearchResult, setUnitSearchResult] = useState(null);
    const [reservationSearchResult, setReservationSearchResult] = useState(null);
    const [user, setUser] = useState(null);

    const cleanUp = () => {
        setPartnerSearchResult(null);
        setPartner(null);
        setPartners(null);
        setSelectedPartnerId(null);
        setUnitSearchResult(null);
        setReservationSearchResult(null);
        setUser(null);
    }

    return  <>    
        <AuthContext.Provider value={ { user: user, setUser, login: login, cleanUp: cleanUp }}>
            <PartnerContext.Provider value={ { partnerSearchResult, setPartnerSearchResult, partner, setPartner, partners, setPartners, setSelectedPartnerId, selectedPartnerId }}>
                <UnitContext.Provider value={ { unitSearchResult, setUnitSearchResult }}>
                    <ReservationContext.Provider value={ { reservationSearchResult, setReservationSearchResult }}>
                        {children}
                    </ReservationContext.Provider>
                </UnitContext.Provider>
            </PartnerContext.Provider>
        </AuthContext.Provider>
    </>

}