import { ExpandMore, Hotel, List, MeetingRoom } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, TextField } from "@mui/material";
import { Col, Container, Row } from "reactstrap";
import PartnerSelect from "../../components/PartnerSelect";
import Loader from "../../../../components/Loader/Loader";
import OrderByIconButton from "../../components/OrderByIconButton";

type CheckInsFilterProps = {
    showPartnerSelect: Boolean, 
    partners: Array<any>, 
    handlePartnerChange: (partnerId: string) => {}, 
    handleClearSelection: () => void,
    isLoading: Boolean, 
    unitName: string, 
    unitOccupied: Boolean, 
    occupiedCount: number,
    vacantCount: number,
    totalCount: number,
    hasSelectedFilter: Boolean
    sortUnitsBy: string,
    onUnitNameChange: (unitName: string, submit: Boolean) => {}, 
    onUnitAvailabilityChange: (occupied?: boolean) => void,
    onSortUnitByChange: (selected: string) => {}
}

export default function CheckInsFilter({ 
    showPartnerSelect, 
    partners, 
    handlePartnerChange, 
    handleClearSelection,
    isLoading, 
    unitName, 
    unitOccupied, 
    occupiedCount,
    vacantCount,
    totalCount,
    hasSelectedFilter,
    sortUnitsBy,
    onUnitNameChange, 
    onUnitAvailabilityChange,
    onSortUnitByChange
 }: CheckInsFilterProps){

    const handleAvailabilityChange = async (occupied?: boolean) => {
        await onUnitAvailabilityChange(occupied);
    }

    const handletUnitNameSubmit = async (e: any) => {
        e.preventDefault();

        await onUnitNameChange(unitName, true);
    }

    const handletUnitNameChange = async (unitName: string) => {
        await onUnitNameChange(unitName, false);
    }

    return <>
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="settings-content"
                id="settings-header"
            >
                    <span className="h2 mb-3">Filters</span>
            </AccordionSummary>
            <AccordionDetails>
                    <Container fluid className="mb-2">
                        <Row>
                            {showPartnerSelect === true ? 
                                <Col lg={3}>
                                    <PartnerSelect
                                        partners={partners}
                                        onChange={handlePartnerChange}
                                        onClearSelection={handleClearSelection}
                                        withLoading={false}
                                        enableSelectAll={false}
                                        showPlaceHolder={true}
                                        selectedPartnerId={null}
                                        m={{}}
                                    />
                                </Col> 
                            : null}
                            <Col lg={3}>
                                <form onSubmit={(e) => handletUnitNameSubmit(e)}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField 
                                            value={unitName}
                                            onChange={(e: any) => handletUnitNameChange(e.target.value)}
                                            fullWidth
                                            label="Unit Name"
                                            placeholder="Type the unit name"
                                        />
                                    </FormControl>
                                </form>
                            </Col>
                        </Row> 
                        
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                                {isLoading === true ? <Loader height={40} width={40} /> : null}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg={6}>
                                <Button 
                                    startIcon={<List />} 
                                    variant={(unitOccupied === null ? "contained" : "outlined")} 
                                    onClick={() => handleAvailabilityChange(null)}  
                                    size="small"
                                    sx={ { marginRight: '5px' } }
                                >
                                    All {(hasSelectedFilter === true ? `(${totalCount})` : null)}
                                </Button>
                                <Button 
                                    startIcon={<Hotel />} 
                                    variant={(unitOccupied === true ? "contained" : "outlined")}
                                    onClick={() => handleAvailabilityChange(true)} 
                                    size="small"
                                    sx={ { marginRight: '5px' } }>
                                    Occupied {(hasSelectedFilter === true ? `(${occupiedCount})` : null)}
                                </Button>
                                <Button 
                                    startIcon={<MeetingRoom />} 
                                    variant={(unitOccupied === false ? "contained" : "outlined")} 
                                    onClick={() => handleAvailabilityChange(false)} 
                                    size="small"
                                    sx={ { marginRight: '10px' } }>
                                    Vacant {(hasSelectedFilter === true ? `(${vacantCount})` : null)}
                                </Button>
                                <OrderByIconButton 
                                    options={[{ id: '-Occupied', name: "Occupied" }, { id: '+Occupied', name: "Vacant" }, { id: '+UnitName', name: "Unit Name"}]}
                                    selected={sortUnitsBy}
                                    onChange={(selected: string) => onSortUnitByChange(selected)}
                                />
                            </Col>
                        </Row>

                    </Container>
            </AccordionDetails>
        </Accordion>
    </>
}