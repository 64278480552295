import { AlertColor } from "@mui/material";
import { useState } from "react";


export const useSnack = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return { isOpen, onOpen, onClose, message, setMessage, severity, setSeverity };
};
