/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, FormControl, InputLabel, TextField, SelectChangeEvent } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import { searchForReservations } from "../../../actions/reservation-actions";
import ReservationsTable from "../components/tables/ReservationsTable";
import Pagination from "../components/Pagination";
import PartnerSelect from "../components/PartnerSelect";
import { searchForUnits } from "../../../actions/unit-actions";
import UnitSelect from "../components/UnitSelect";
import Loader from "../../../components/Loader/Loader";
import DateField from "../../../components/Date/DateField";
import moment from "moment";
import { arrayNotNullAndHasEntries, isNotNullOrEmpty } from "../../../util/null-helper";
import { IsAdmin } from "../../../util/role-helpers";
import { convertTo13DigitsTimeStamp } from "../../../util/time-helpers";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGE_NUMBER = 1;

function Reservations() {

    const [loadingReservations, setLoadingReservations] = useState(false);
    const [reservationSearchResult, setReservationSearchResult] = useState(null);
    const [unitSearchResult, setUnitSearchResult] = useState(null);
    const [partnerId, setPartnerId] = useState(null);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [sortBy, setSortBy] = React.useState('+ArrivalTimestamp');
    const [tuyaUnitId, setTuyaUnitId] = useState(null);
    const [reservationStatus, setReservationStatus] = useState('All');
    const [externalReservationId, setExternalReservationId] = useState(null);
    const [lockerPassword, setLockerPassword] = useState(null);
    const [deviceId, setDeviceId] = useState(null);

    const unitName = null;
    
    const [checkInFromDate, setCheckInFromDate] = useState(null);
    const [checkInFromDateTimeStamp, setCheckInFromDateTimeStamp] = useState(null);
    const [checkInUntilDate, setCheckInUntilDate] = useState(null);
    const [checkInUntilDateTimeStamp, setCheckInUntilDateTimeStamp] = useState(null);

    const [checkoutFromDate, setCheckoutFromDate] = useState(null);
    const [checkoutFromDateTimeStamp, setCheckoutFromDateTimeStamp] = useState(null);
    const [checkoutUntilDate, setCheckoutUntilDate] = useState(null);
    const [checkoutUntilDateTimeStamp, setCheckoutUntilDateTimeStamp] = useState(null);
    const { partners } = React.useContext<PartnerContextType>(PartnerContext);

    var { role } = useAuth();

    const loadReservations = useCallback(async (partnerId: string, 
        externalReservationId: string, 
        tuyaUnitId: string, 
        reservationStatus: string, 
        pageSize: number, 
        pageNumber: number, 
        checkInFromDateTimeStamp: number, 
        checkInUntilDateTimeStamp: number, 
        checkoutFromDateTimeStamp: number, 
        checkoutUntilDateTimeStamp: number, 
        deviceId: string,
        lockerPassword: string,
        sortBy: string) => {
            try {
                setLoadingReservations(true);

                const status = reservationStatus === 'All' ? null : reservationStatus;
                
                const response = await searchForReservations(
                    partnerId, 
                    externalReservationId, 
                    tuyaUnitId, 
                    unitName,
                    status,
                    pageSize, 
                    pageNumber - 1, 
                    checkInFromDateTimeStamp, 
                    checkInUntilDateTimeStamp, 
                    checkoutFromDateTimeStamp, 
                    checkoutUntilDateTimeStamp, 
                    deviceId,
                    lockerPassword, 
                    sortBy);
        
                setReservationSearchResult(response.data);
                setLoadingReservations(false);
            }
            catch(err){
                console.log(err);
                setLoadingReservations(false);
            }
    }, []);

    const setDefaultPartner = useCallback((partners) => {
        if (arrayNotNullAndHasEntries(partners) && partners.length === 1) {
            setPartnerId(partners[0].id);
        }
    }, []); 

    const loadUnits = useCallback(async (partnerId) => {
        const response = await searchForUnits(partnerId, null, 300, 0, '+Name');
        setUnitSearchResult(response.data);
    }, []); 

    const convertToTimeStamp = (val) => {
        return isNotNullOrEmpty(val) ? moment(val).unix() : null;;
    }

    const handlePageChange = async (e, newPage) => {
        setPageNumber(newPage);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, newPage, 
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }
    
    const handlePartnerChange = async (partnerId: string) => {
        setPartnerId(partnerId);
        setPageNumber(1);
        await Promise.all([
          loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, 1,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy),
          loadUnits(partnerId)
        ]);
      };

    const handleUnitChange = async (tuyaUnitId) => {
        setPageNumber(1);
        setTuyaUnitId(tuyaUnitId);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleExternalIdChange = async (e) => {
        e.preventDefault();
        setPageNumber(1);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleDeviceIdChange = async (e) => {
        e.preventDefault();
        setPageNumber(1);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleLockerPasswordChange = async (e) => {
        e.preventDefault();
        setPageNumber(1);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handlePageSizeChange = async (e: SelectChangeEvent) => {
        const newSize =  parseInt(e.target.value);
        setPageNumber(1);
        setPageSize(newSize);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, newSize, pageNumber,
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleClickSort = async (direction, field) => {
        const newSort = `${direction}${field}`;
        setPageNumber(1);
        setSortBy(newSort);
        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber, 
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, newSort);
    }
    
    const handleCheckinDateFromChange = async (val, setDateTime, setDateTimeStamp) => {
        setDateTime(val);
        const timestamp = convertToTimeStamp(val);
        const timestampStr = convertTo13DigitsTimeStamp(timestamp);
        setDateTimeStamp(timestampStr);
        setPageNumber(1);

        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber, 
            timestampStr, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }


    const handleCheckinDateUntilChange = async (val, setDateTime, setDateTimeStamp) => {
        setDateTime(val);
        const timestamp = convertToTimeStamp(val);
        const timestampStr = convertTo13DigitsTimeStamp(timestamp);
        setDateTimeStamp(timestampStr);
        setPageNumber(1);

        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber, 
            checkInFromDateTimeStamp, timestampStr, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleCheckoutDateFromChange = async (val, setDateTime, setDateTimeStamp) => {
        setDateTime(val);
        const timestamp = convertToTimeStamp(val);
        const timestampStr = convertTo13DigitsTimeStamp(timestamp);
        setDateTimeStamp(timestampStr);
        setPageNumber(1);

        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber, 
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, timestampStr, checkoutUntilDateTimeStamp, 
            deviceId, lockerPassword, sortBy);
    }

    const handleCheckoutUntilChange = async (val, setDateTime, setDateTimeStamp) => {
        setDateTime(val);
        const timestamp = convertToTimeStamp(val);
        const timestampStr = convertTo13DigitsTimeStamp(timestamp);
        setDateTimeStamp(timestampStr);
        setPageNumber(1);

        await loadReservations(partnerId, externalReservationId, tuyaUnitId, reservationStatus, pageSize, pageNumber, 
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, timestampStr, 
            deviceId, lockerPassword, sortBy);
    }

    const handleStatusChange = async (e) => {
        const status = e.target.value;
        setReservationStatus(status);
        setPageNumber(1);

        await loadReservations(partnerId, externalReservationId, tuyaUnitId, status, pageSize, pageNumber, 
            checkInFromDateTimeStamp, checkInUntilDateTimeStamp, checkoutFromDateTimeStamp, checkoutUntilDateTimeStamp, deviceId,
             lockerPassword, sortBy);
    }

    const loadInitialReservations = useCallback(async () => {
        await loadReservations(null, null, null, null, INITIAL_PAGE_SIZE, INITIAL_PAGE_NUMBER, null, null, null, null, null, null, null);
    }, [loadReservations]); 

    const onPageLoad = useCallback(async () => {
        await Promise.all([loadInitialReservations()]);
        await loadUnits(null);
    }, [loadInitialReservations, loadUnits]);
    
    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);

    useEffect(() => {
        setDefaultPartner(partners);
    }, [partners, setDefaultPartner]);

    return (
        <>
        <CardsHeader name="Reservations" parentName="Dashboard" />
        <Container className="mt--6" fluid>
            <Row style={{ marginBottom:"20px" }}>
                <Col>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="settings-content"
                            id="settings-header"
                        >
                                <span className="h2 mb-3">Filters</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="mb-5 mr-5">
                                {IsAdmin(role) ? <Row>
                                    <Col lg={3}>
                                        <PartnerSelect
                                            partners={partners}
                                            onChange={handlePartnerChange}
                                            withLoading={false}
                                            enableSelectAll={true}
                                            showPlaceHolder={false}
                                            selectedPartnerId={null}
                                            m={null}
                                        />
                                    </Col> 
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleDeviceIdChange(e)}> 
                                            <FormControl sx={{ m: 2, width: '100%' }}>
                                                <TextField
                                                    id="device_id"
                                                    label="Device ID"
                                                    onChange={(e) => setDeviceId(e.target.value)}
                                                    value={deviceId}
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                </Row> : null}
                                <Row>
                                   
                                    <Col lg={3}>
                                        <UnitSelect
                                            units={unitSearchResult ? unitSearchResult.items : []}
                                            onChange={handleUnitChange}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleExternalIdChange(e)}> 
                                            <FormControl sx={{ m: 2, width: '100%' }}>
                                                <TextField
                                                    id="external_res_id"
                                                    label="Reservation ID"
                                                    onChange={(e) => setExternalReservationId(e.target.value)}
                                                    value={externalReservationId}
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl sx={{ m: 2, width: '100%' }}>
                                            <InputLabel id="status-filter">Status</InputLabel>
                                            <Select
                                                labelId="status-filter"
                                                id="page-size-select"
                                                value={reservationStatus}
                                                label="Status"
                                                onChange={handleStatusChange}
                                            >
                                                <MenuItem value={'All'}>All</MenuItem>
                                                <MenuItem value={'Pending'}>Pending</MenuItem>
                                                <MenuItem value={'PendingCheckIn'}>PendingCheckIn</MenuItem>
                                                <MenuItem value={'CheckedIn'}>CheckedIn</MenuItem>
                                                <MenuItem value={'CheckedOut'}>CheckedOut</MenuItem>
                                                <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                                                <MenuItem value={'Closed'}>Closed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleLockerPasswordChange(e)}> 
                                            <FormControl sx={{ m: 2, width: '100%' }}>
                                                <TextField
                                                    id="locker_pass"
                                                    label="Door Lock Password"
                                                    onChange={(e) => setLockerPassword(e.target.value)}
                                                    value={lockerPassword}
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl sx={{ m: 2, width: '100%' }}>
                                            <DateField
                                                label="Check-in From Date"
                                                value={checkInFromDate}
                                                onChange={(newValue) => handleCheckinDateFromChange(newValue, setCheckInFromDate, setCheckInFromDateTimeStamp)}
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl sx={{ m: 2, width: '100%' }}>
                                            <DateField
                                                label="Check-in Until Date"
                                                value={checkInUntilDate}
                                                onChange={(newValue) => handleCheckinDateUntilChange(newValue, setCheckInUntilDate, setCheckInUntilDateTimeStamp)}
                                            />
                                        </FormControl>
                                    </Col>

                                    <Col lg={3}>
                                        <FormControl sx={{ m: 2, width: '100%' }}>
                                            <DateField
                                                label="Check-out From Date"
                                                value={checkoutFromDate}
                                                onChange={(newValue) => handleCheckoutDateFromChange(newValue, setCheckoutFromDate, setCheckoutFromDateTimeStamp)}
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl sx={{ m: 2, width: '100%' }}>
                                            <DateField
                                                label="Check-out Until Date"
                                                value={checkoutUntilDate}
                                                onChange={(newValue) => handleCheckoutUntilChange(newValue, setCheckoutUntilDate, setCheckoutUntilDateTimeStamp)}
                                            />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                                        {loadingReservations === true ? <Loader /> : null}
                                    </Col>
                                    <Col lg={12}>
                                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                                            <Typography>
                                                ({reservationSearchResult ? reservationSearchResult.totalCount : 0}) records found.
                                            </Typography>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            <Row>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {reservationSearchResult ? <Pagination 
                        totalPages={reservationSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
                <Col>
                    <ReservationsTable 
                        reservations={reservationSearchResult ? reservationSearchResult.items : []}
                        handleClickSort={handleClickSort}
                        handlePageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        role={role}
                        sortBy={sortBy}
                        isLoading={loadingReservations}
                    />

                </Col>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {reservationSearchResult ? <Pagination 
                        totalPages={reservationSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                    {loadingReservations === true ? <Loader /> : null}
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Reservations;
