/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from "react";
// reactstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";
import { useNavigate } from "react-router-dom";
import { isNotNullOrEmpty } from "../../../util/null-helper";
import { listPartnerStatistics } from "../../../actions/statistic-actions";
import Loader from "../../../components/Loader/Loader";

function Partners() {
  const partnerContext = React.useContext<PartnerContextType>(PartnerContext);
  const { partners, setPartner } = partnerContext;
  const [partnersStatistics, setPartnersStatistic] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();


  const loadPartnerStatistics = useCallback(async () => {
    const response = await listPartnerStatistics();
    setPartnersStatistic(response.data);
  }, []);

  const onPageLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      await Promise.all([loadPartnerStatistics()]);
    } catch(err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
    
  }, [loadPartnerStatistics]);

  useEffect(() => {
    onPageLoad();
  }, [onPageLoad]);

  const handleClickPartner = (e, partner) => {
    e.preventDefault();
    setPartner(partner);
    navigate(`/admin/client/settings/${partner.id}`)
  }

  const getStatistic = (partnerId, getValue) => {
    const partner = partnersStatistics.filter((val) => val.partnerId === partnerId)[0];

    return partnersStatistics.length > 0 && isNotNullOrEmpty(partner)
      ? getValue(partner)
      : 0;
  }

  return (
    <>
      <CardsHeader name="Client Settings" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Client name</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Active Units</th>
                    <th scope="col">Inactive Units</th>
                    <th scope="col">Reservations</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {partners ? partners.map((i) => {
                    return <tr key={`partner_${i.id}`}>
                      <th scope="row">{i.name}</th>
                      <td>{i.enabled === true ? "Yes" : "No"}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalActiveUnits : 0)}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalInactiveUnits : 0)}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalReservations : 0)}</td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href="#easyhub"
                          id="tooltip183793484"
                          onClick={(e) => handleClickPartner(e, i)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action-delete"
                          href="#easyhub"
                          id="tooltip12475020"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </td>
                    </tr>
                  }) : null}
                </tbody>
              </Table>
            </Card>
          </Col>
          {isLoading === true ? <Col lg={12} className="d-flex justify-content-center">
            <Loader />
          </Col> : null}
        </Row>
      </Container>
    </>
  );
}

export default Partners;
