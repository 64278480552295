import axios from "axios"
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";
import { createClient } from "../util/axios-helpers";

export const searchForPartners = (partnerId: string, pageSize: number, pageIndex: number, sortBy: string) => {
    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/partners?${params}`));
}