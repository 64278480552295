import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type DateFieldProps = {
  label: string, 
  value: string, 
  onChange: (e: any) => void;
}

export default function DateField({ label, value, onChange }: DateFieldProps){
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          label={label}
          value={value}
          onChange={onChange}
        />
    </LocalizationProvider>
  );
}