/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from "react";
// node.js library that concatenates classes (strings)
import {
  Container,
  Row,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";

import { listGeneralStatistics } from "../../../actions/statistic-actions";
import GeneralStatistic from "./cards/GeneralStatistic";
import ReservationsOverTime from "./cards/ReservationsOverTime";
import isEqual from "lodash/isEqual";
import { useAuth } from "../../../hooks/useAuth";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";
import { arrayNotNullAndHasEntries } from "../../../util/null-helper";

function Dashboard() {
  const [generalStatistics, setGeneralStatistics] = React.useState([]);
  const [loadingStatistics, setLoadingStatistics] = React.useState(false);
  const { partners, selectedPartnerId, setSelectedPartnerId } = React.useContext<PartnerContextType>(PartnerContext);
  const { role } = useAuth();

  const loadGeneralStatistics = useCallback(async (partnerId: string | null) => {
    setLoadingStatistics(true);
    try {
      const response = await listGeneralStatistics(partnerId);
      setGeneralStatistics(prevState => 
        isEqual(prevState, response.data) ? prevState : response.data
      );
    } catch (error) {
      console.error("Error loading general statistics:", error);
    } finally {
      setLoadingStatistics(false);
    }
  }, []); 

  const handlePartnerChange = useCallback(async (partnerId: string): Promise<void> => {
    setSelectedPartnerId(partnerId);
    await loadGeneralStatistics(partnerId);
  }, [setSelectedPartnerId, loadGeneralStatistics]); 

  const handleClearSelection = () => {
    setSelectedPartnerId(null);
    setGeneralStatistics([]);
  }

  const onPageLoad = useCallback(async () => {
      if(arrayNotNullAndHasEntries(partners) && partners.length === 1){
        handlePartnerChange(partners[0].id);
      }
  }, [partners, handlePartnerChange]);

  useEffect(() => {
    onPageLoad();
  }, [onPageLoad]); 

  return (
    <>
      <CardsHeader name="" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <GeneralStatistic 
            statistics={generalStatistics} 
            loading={loadingStatistics} 
            onPartnerChange={handlePartnerChange}
            onClearSelection={handleClearSelection}
            partners={partners}
            partnerId={selectedPartnerId}
            role={role}
          />
        </Row>
        <Row>
          <ReservationsOverTime statistics={generalStatistics} loading={loadingStatistics}  />
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
