
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

type DeletePasswordDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    reservation: any;
    onConfirmDelete: (reservation: any) => void;
};

export default function DeletePasswordDialog({
    isOpen,
    onClose,
    reservation,
    onConfirmDelete,
}: DeletePasswordDialogProps) {
    
    const handleConfirmDelete = () => {
        onConfirmDelete(reservation);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="delete-password-dialog-title">
            <DialogTitle id="delete-password-dialog-title">Delete Password</DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to reset the password for reservation ID: <strong>{reservation ? reservation.externalReservationId : null}</strong>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
