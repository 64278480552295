import { RotatingLines } from "react-loader-spinner";
import { isNotNullOrEmpty } from "../../util/null-helper";

type SecondaryLoaderProps = {
    height?: number,
    width?: number,
    color?: string
}
export default function SecondaryLoader({ width, color }: SecondaryLoaderProps){
    return <RotatingLines
        visible={true}
        width={isNotNullOrEmpty(width) ? width.toString() : "40"}
        ariaLabel="three-circles-loading"
        strokeColor={color}
    />
}