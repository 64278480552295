import React, { useState } from "react";
import { Circle, FileCopy } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, Tooltip, Typography } from "@mui/material";
import { Card, CardBody, Collapse, Col, Row, Table, Container } from "reactstrap";
import { red } from "@mui/material/colors";
import SortButton from "../../../../components/Headers/SortButton";
import Loader from "../../../../components/Loader/Loader";
import DeletePasswordDialog from "../dialogs/DeletePasswordDialog";
import LockerCodeColumnCell from "./LockerCodeColumnCell";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import { IsAdmin } from "../../../../util/role-helpers";
import { isNotNullOrEmpty } from "../../../../util/null-helper";
import "./../style.css";
import { useSnack } from "../../../../hooks/useSnack";

type ReservationsTableProps = {
  reservations: Array<any>,
  pageSize: number, 
  role: string, 
  sortBy: string, 
  isLoading: boolean,
  tableLegend?: string,
  handlePageSizeChange: (e: SelectChangeEvent) => void,
  handleClickSort: (direction: string, column: string) => void
}
const ReservationsTable = ({
  reservations,
  pageSize,
  role,
  sortBy,
  isLoading,
  tableLegend,
  handlePageSizeChange,
  handleClickSort,
}: ReservationsTableProps) => {
  const [openedCollapses] = useState(["collapseOne"]);
  const [reservationIdToDeletePassword, setReservationIdToDeletePassword] = useState(null);
  const [deletePasswordDialogOpen, setDeletePasswordDialogOpen] = useState(false);
  const snack = useSnack();

  const handleConfirmDelete = (reservation: any) => {
    setDeletePasswordDialogOpen(false);

    snack.setMessage(`Success! Reservation ${reservation.externalReservationId} will re-sync the password in a couple of minutes.`);
    snack.onOpen();
  };

  const handleClickDeletePassword = (reservation: any) => {
    setReservationIdToDeletePassword(reservation);
    setDeletePasswordDialogOpen(true);
  }

  return (
    <>
      <div className="accordion">
        <Card className="card-plain">
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseOne")}
          >
            <CardBody>
              <Container fluid>
                <Row>
                  {isNotNullOrEmpty(tableLegend) && (
                    <Col xl={12} className="mb-3">
                      <Typography
                        color={EasyHubPrimaryColor}
                        fontSize="12px"
                        fontStyle="italic"
                      >
                        {tableLegend}
                      </Typography>
                    </Col>
                  )}
                  <Col xl={12}>
                    {isLoading && (
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top: "40%",
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">
                            Id{" "}
                            <SortButton
                              column="Id"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "Id")
                              }
                            />
                          </th>
                          <th scope="col">
                            Unit{" "}
                            <SortButton
                              column="RoomNo"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "RoomNo")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Reservation Id{" "}
                            <SortButton
                              column="ExternalReservationId"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "ExternalReservationId")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Status{" "}
                            <SortButton
                              column="Status"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "Status")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Guest Name{" "}
                            <SortButton
                              column="ContactFirstName"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "ContactFirstName")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Door Lock Code{" "}
                            <SortButton
                              column="LockerPassword"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "LockerPassword")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Check-in{" "}
                            <SortButton
                              column="ArrivalTimestamp"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "ArrivalTimestamp")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Check-out{" "}
                            <SortButton
                              column="DepartureTimestamp"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "DepartureTimestamp")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Create Date{" "}
                            <SortButton
                              column="CreateDate"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "CreateDate")
                              }
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Last Modified{" "}
                            <SortButton
                              column="UpdateDate"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "UpdateDate")
                              }
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reservations &&
                          reservations.map((i) => (
                            <tr key={`unit_${i.id}`}>
                              <th scope="row">
                                <Tooltip title={i.id}>
                                  <div
                                    className="hover"
                                    onClick={() => {
                                      navigator.clipboard.writeText(i.id);
                                    }}
                                  >
                                    <FileCopy />
                                  </div>
                                </Tooltip>
                              </th>
                              <td style={{ textAlign: "center" }} className="d-flex">
                                {IsAdmin(role) && i.deviceOffline && (
                                  <div className="pr-1">
                                    <Tooltip title="Failed to send last update to the Door Lock. The Device was offline the moment the Integration attempted to create the Door Lock Code.">
                                      <Circle sx={{ color: red[500] }} />
                                    </Tooltip>
                                  </div>
                                )}
                                <Tooltip title={i.roomId}>
                                  <div className="mt-1">{i.roomNo}</div>
                                </Tooltip>
                              </td>
                              <td style={{ textAlign: "center" }}>{i.externalReservationId}</td>
                              <td style={{ textAlign: "center" }}>{i.status}</td>
                              <td style={{ textAlign: "center" }}>
                                {i.contactFirstName} {i.contactLastName}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <LockerCodeColumnCell
                                  reservation={i}
                                  isAdmin={IsAdmin(role)}
                                  onClickDeletePassword={handleClickDeletePassword}
                                  enableDelete={false}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {IsAdmin(role) ? (
                                  <Tooltip title={i.arrivalTimestamp}>
                                    <div>
                                      {`${i.arrivalDate} ${i.arrivalDateTime}`}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  `${i.arrivalDate} ${i.arrivalDateTime}`
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {IsAdmin(role) ? (
                                  <Tooltip title={i.departureTimestamp}>
                                    <div>
                                      {`${i.departureDate} ${i.departureDateTime}`}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  `${i.departureDate} ${i.departureDateTime}`
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>{i.createDate}</td>
                              <td style={{ textAlign: "center" }}>{i.updateDate}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Col xl={{ size: 2, offset: 10 }}>
                      <FormControl sx={{ m: 2, width: "100%" }}>
                        <InputLabel id="page-size-filter">Items per page</InputLabel>
                        <Select
                          labelId="page-size-filter"
                          id="page-size-select"
                          value={pageSize.toString()}
                          label="Page Size"
                          onChange={handlePageSizeChange}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={50}>25</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={200}>200</MenuItem>
                          <MenuItem value={300}>300</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Collapse>
        </Card>
        <DeletePasswordDialog
          isOpen={deletePasswordDialogOpen}
          reservation={reservationIdToDeletePassword}
          onClose={() => setDeletePasswordDialogOpen(false)}
          onConfirmDelete={handleConfirmDelete}
        />
      <Snackbar
          open={snack.isOpen}
          autoHideDuration={6000}
          onClose={snack.onClose}
          message={snack.message}
      />
      </div>
    </>
  );
};

export default ReservationsTable;
