import MUIPagination from '@mui/material/Pagination';

type PaginationProps = {
  totalPages: number, 
  onChange: (e: any, newPage: any) => void, 
  page: number, 
  float?: any
}

export default function Pagination({ totalPages, onChange, page, float }: PaginationProps) {

    return (
      <>
      <div style={{ float: float}}>
          <MUIPagination 
            count={totalPages} 
            color="primary" 
            onChange={onChange}
            page={page}
          />
      </div>
      </>
    );
}