/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback } from "react";
// react library for routing
import { useLocation, Route, Routes, useNavigate } from "react-router-dom";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes";
import { useAuth } from "../hooks/useAuth";
import { getUserDetails } from "../actions/user-actions";
import { isNotNullOrEmpty } from "../util/null-helper";
import { getReservation } from "../actions/reservation-actions";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import moment from "moment";
import { INTERCOM_APP_ID } from "../constants/intercom";
import { AuthContext, AuthContextType } from "../contexts/auth-context";
import { PartnerContext, PartnerContextType } from "../contexts/partner-context";
import { searchForPartners } from "../actions/partner-actions";
import { IsAdmin } from "../util/role-helpers";

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const { user, setUser, cleanUp } = React.useContext<AuthContextType>(AuthContext);
  const { partners, setPartnerSearchResult, setPartners } = React.useContext<PartnerContextType>(PartnerContext);


  const { token, loaded, logout, role } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const loadUserDetails = useCallback(async () => {
    const response = await getUserDetails();

    const user = response.data;
    setUser(user);

    if(IsAdmin(user.role)) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        name: `${user.firstName} ${user.lastName}`,
        email: user.username,
        user_id: user.id,
        created_at: moment(user.createDate).unix(),
        user_hash: user.userHash
      });
    }
  }, [setUser]);


  const loadPartners = useCallback(async () => {
    try {
      const response = await searchForPartners(null, 200, 0, null);
      setPartnerSearchResult(response.data);
      setPartners(response.data.items);
    } catch (error) {
      console.error("Error loading partners:", error);
    }
  }, [setPartnerSearchResult, setPartners]);

  const handleSearchReservation = async (externalId) => {
    var response = await getReservation(externalId);
    return response;
  }

  React.useEffect(() => {
    if (loaded === true) {
      if(isNotNullOrEmpty(token)) {
        if(user === null){
          loadUserDetails();
        }
      }
      else {
        navigate('/auth/login');
      }
    }
  }, [loaded, navigate, token, user, loadUserDetails]);

  React.useEffect(() => {
    if (loaded === true) {
        if(partners === null){
          loadPartners();
        }
    }
  }, [loaded, partners, loadPartners]); 


  const getRoutes = (routes, role) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views, role);
      }
      if (prop.layout === "/admin" && (prop.roles === undefined || (prop.roles && prop.roles.indexOf(role) >= 0))) {
        return (
          <Route path={prop.path} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  const handleLogout = () => {
    cleanUp();
    shutdown();
    logout();
    navigate('/auth/login');
  }

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: "https://easyhub.ai/wp-content/uploads/2024/02/logo-easyhub.png",
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef} style={ { minHeight: '100vh'}}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          fullName={user ? `${user.firstName} ${user.lastName}` : "..."}
          logout={handleLogout}
          onSearchReservation={handleSearchReservation}
          role={role}
          cleanup={cleanUp}
        />
        <Routes>
          {getRoutes(routes, role)}
        </Routes>
        <div className="pt-5">
          <AdminFooter />
        </div>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
