import { createContext, Dispatch } from "react";

export type PartnerContextType = {
  partnerSearchResult: any,
  partners: Array<any>,
  selectedPartnerId: string,
  setSelectedPartnerId: Dispatch<any>,
  setPartnerSearchResult: Dispatch<any>,
  setPartners: Dispatch<any>,
  partner: any,
  setPartner: Dispatch<any>
}

export const PartnerContext = createContext<PartnerContextType>({
  partnerSearchResult: null,
  partners: [],
  selectedPartnerId: null,
  setSelectedPartnerId: () => {},
  setPartnerSearchResult: () => {},
  setPartners: () => {},
  partner: null,
  setPartner: () => {}
});