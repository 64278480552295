import axios from "axios"
import { createClient } from "../util/axios-helpers";
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";

export const listGeneralStatistics = (partnerId: string) => {
    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId
    });

    return client.get(endpoint(`api/v1/statistics/general?${params}`));
}

export const listPartnerStatistics = () => {
    const client = createClient(axios);

    return client.get(endpoint(`api/v1/statistics/partners`));
}