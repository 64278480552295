import axios from "axios"
import { createClient } from "../util/axios-helpers";
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";

export const searchForUnits = (partnerId: string, name: string, pageSize: number, pageIndex: number, sortBy: string) => {

    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        name,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/units?${params}`));
}

export const enableUnits = (unitIds: Array<string>, isEnabled: boolean, integrationType: string) => {

    const client = createClient(axios);

    return client.put(endpoint(`api/v1/units`), {
        unitIds,
        isEnabled,
        integrationType
    });
}