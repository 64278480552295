import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { isNotNullOrEmpty } from "../../../../util/null-helper";
import './chart.css';  // Import your custom CSS
import Loader from '../../../../components/Loader/Loader';
import { ApexOptions } from 'apexcharts';

const ReservationsOverTime = ({ statistics, loading }) => {

    const processReservationsData = (statistics) => {
        let series = [];
        let categories = [];

        if (isNotNullOrEmpty(statistics)) {
            statistics.forEach((statistic) => {
                let data = [];
                const reservationsPerMonth = statistic.reservationsPerMonth;

                if (reservationsPerMonth) {
                    // Extracting the months from the first partner as the x-axis categories
                    if (categories.length === 0) {
                        categories = Object.keys(reservationsPerMonth);
                    }

                    // Extracting the reservations count
                    data = Object.values(reservationsPerMonth);
                }

                series.push({
                    name: statistic.partnerName,
                    data: data
                });
            });
        }
        return { series, categories };
    }

    const { series, categories } = processReservationsData(statistics);

    const options: ApexOptions = {
        chart: { type: 'line', background: 'transparent' }, 
        title: { text: 'Reservations Over Time', align: 'center' },
        xaxis: { 
            categories: categories,
            title: { text: 'Month' },
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            title: { text: 'Reservations' },
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        tooltip: {
            theme: 'dark'  
        },
        stroke: { curve: 'smooth' },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
    };

    return (
        <>
            {isNotNullOrEmpty(statistics) ? <Col xl="12">
                <Card>
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-light text-uppercase ls-1 mb-3">Statistics</h6>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {loading === false ? <ReactApexChart options={options} series={series} type="line" height={350} /> : <div className="d-flex justify-content-center">
                            <Loader height={40} width={40} color={"#fff"} />
                        </div>}
                    </CardBody>
                </Card>
            </Col>: null}
        </>
    );
}

export default ReservationsOverTime;
