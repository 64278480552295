import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const NoMaxWidthTooltip = styled(({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} >
      {children}
    </Tooltip>
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
});