import { Col, Container, Row } from "reactstrap";
import CardsHeader from "../../../components/Headers/CardsHeader";
import CheckInsFilter from "./components/CheckInsFilter";
import { useCallback, useContext, useEffect, useState } from "react";
import CheckInsTable from "./components/CheckInsTable";
import { searchForCheckIns } from "../../../actions/move-in-actions";
import { isNotNullOrEmpty, isNullOrEmpty } from "../../../util/null-helper";
import { useAuth } from "../../../hooks/useAuth";
import { ROLES } from "../../../constants/role";
import { IsAdmin } from "../../../util/role-helpers";
import { searchForReservations } from "../../../actions/reservation-actions";
import { SelectChangeEvent } from "@mui/material";
import { EasyHubPrimaryColor } from "../../../variables/colors";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";

const INITIAL_SORT_BY = "-Occupied";

export default function CheckIns(){

    const [checkIns, setCheckIns] = useState<any>();

    const [partnerId, setPartnerId] = useState(null);
    const [pageSize, setPageSize] = useState(600);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    
    const [sortUnitsBy, setUnitsSortBy] = useState(INITIAL_SORT_BY);
    const [unitName, setUnitName] = useState('');
    const [occupied, setOccupied] = useState<boolean>(null);
    const [resultSectionOpen, setResultSectionOpen] = useState<boolean>(true);
    const { partners } = useContext<PartnerContextType>(PartnerContext);

    const { role } = useAuth();

    const loadCheckIns = useCallback(async (partnerId: string, unitName: string, occupied: boolean, sortBy: string) => {
        setIsLoading(true);
        try {
            const response = await searchForCheckIns(partnerId, occupied, unitName, pageSize, 0, sortBy);
            setCheckIns(response.data);
        } catch(err){ 
            console.log(err);
        } finally{
            setIsLoading(false);
        }
    }, []);

    const initialLoad = useCallback(async () => {
        if(role === ROLES.Client) {
            loadCheckIns(null, null, null, INITIAL_SORT_BY);
        }
    }, [loadCheckIns, role]);

    const onPageLoad = useCallback(async () => {
        await Promise.all([
            initialLoad()
        ]);
    }, [
        initialLoad,
        pageSize
    ])

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);

    const handlePartnerChange = async (partnerId) => {
        setCheckIns(null);
        setPartnerId(partnerId);
        setPageNumber(1);
        setUnitName('');
        await loadCheckIns(partnerId, null, occupied, sortUnitsBy);
        setResultSectionOpen(true);
    }

    const handleSortByChange = async (sortBy: string) => {
        setUnitsSortBy(sortBy);
        if(isNotNullOrEmpty(partnerId)){
            await loadCheckIns(partnerId, unitName, occupied, sortBy);
        }
    };

    const handletUnitNameChange = async (unitName: string, submit: boolean) => {
        setUnitName(unitName);
        if(submit === true) {
            setOccupied(null);
            setUnitsSortBy('+UnitName')
            await loadCheckIns(partnerId, unitName, occupied, sortUnitsBy);
        }
    }

    const handleUnitAvailabilityChange = async (occupied?: boolean) => {
        if(isNotNullOrEmpty(partnerId)){
            setOccupied(occupied);
            await loadCheckIns(partnerId, unitName, occupied, sortUnitsBy);
        }
    }

    const handleReservationClickSort = async (
        status: string, 
        tuyaUnitId: string,
        pageSize: number,
        pageNumber: number,
        checkInFromTimeStamp: number,
        sortBy: string) => {

        return await onLoadReservations(partnerId, status, tuyaUnitId, pageSize, pageNumber, checkInFromTimeStamp, sortBy);
    }

    const handleReservationPageSizeChange = async (e: SelectChangeEvent, tuyaUnitId: string, status: string, checkInFromTimeStamp: number, sortBy: string) => {
        const newSize =  parseInt(e.target.value);
        return await onLoadReservations(partnerId, status, tuyaUnitId, newSize, 1, checkInFromTimeStamp, sortBy);
    }

    const handleReservationPageChange = async (tuyaUnitId: string, status: string,pageSize: number, pageNumber: number, checkInFromTimeStamp: number, sortBy: string) => {
        return await onLoadReservations(partnerId, status, tuyaUnitId, pageSize, pageNumber, checkInFromTimeStamp, sortBy);
    }

    const handleClearSelection = () => {
        setCheckIns(null);
    }

    const onLoadReservations = async (
        partnerId: string, 
        status: string,
        tuyaUnitId: string,
        pageSize: number,
        pageNumber: number,
        checkInFromTimeStamp?: number,
        sortBy?: string) => {

        if(isLoading === true) {
            return;
        }

        let result = null;

        try {

            setIsLoading(true);

            const response = await searchForReservations(
                partnerId, 
                null, 
                tuyaUnitId,
                null,
                status, 
                pageSize,
                pageNumber - 1,
                checkInFromTimeStamp,
                null, 
                null, 
                null, 
                null,
                null, 
                isNullOrEmpty(sortBy) ? '+ArrivalTimestamp' : sortBy);
    
            result = response.data;
        }
        catch(err) {
            console.log(err);
            result = null;
        } finally {
            setIsLoading(false);
        }

        return result;
    };

    const EmptyState = () => {
        return <div>
            Please select a <span style={{ color: EasyHubPrimaryColor }}>Client</span> to display <span style={{ color: EasyHubPrimaryColor }}>Check-Ins</span>.
        </div>
    }

    return (
        <>
            <CardsHeader name="Check-Ins" parentName="Dashboard" />
            <Container className="mt--6" fluid>
                <Row>
                    <Col lg={12}>
                        <CheckInsFilter 
                            partners={partners} 
                            handlePartnerChange={handlePartnerChange} 
                            isLoading={isLoading}
                            showPartnerSelect={IsAdmin(role)}
                            unitName={unitName}
                            sortUnitsBy={sortUnitsBy}
                            unitOccupied={occupied}
                            occupiedCount={checkIns ? checkIns.occupiedCount : 0}
                            vacantCount={checkIns ? checkIns.vacantCount : 0}
                            totalCount={checkIns ? checkIns.vacantCount + checkIns.occupiedCount : 0}
                            hasSelectedFilter={isNotNullOrEmpty(partnerId) === true}
                            onUnitNameChange={handletUnitNameChange}
                            onUnitAvailabilityChange={handleUnitAvailabilityChange}
                            handleClearSelection={handleClearSelection}
                            onSortUnitByChange={handleSortByChange}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={12} className="pb-3">
                        <CheckInsTable
                            partnerId={partnerId}
                            isLoading={isLoading}
                            role={role}
                            showPartnerName={false}
                            partnerName={null}
                            checkIns={checkIns ? checkIns.items : []} 
                            sortUnitsBy={sortUnitsBy}
                            showEmptyContent={partnerId === null}
                            selectFilterMessage={<EmptyState />}
                            resultSectionOpen={resultSectionOpen}
                            onResultSectionOpenChange={(open: boolean) => setResultSectionOpen(open)}
                            onLoadReservations={onLoadReservations}
                            onReservationClickSort={handleReservationClickSort}
                            onReservationPageSizeChange={handleReservationPageSizeChange}
                            onReservationPageChange={handleReservationPageChange}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}