import axios from "axios"
import { createClient } from "../util/axios-helpers";
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";

export const searchForCheckIns = (partnerId: string, occupied?: boolean, name?: string, pageSize?: number, pageIndex?: number, sortBy?: string) => {

    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        occupied,
        name,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/units/move-ins?${params}`));
}