import React, { useState } from "react";
import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Menu, MenuItem } from "@mui/material";
import { isNotNullOrEmpty } from "../../../../util/null-helper";
import { Container } from "reactstrap";
import { MoreVert } from "@mui/icons-material";
import { RESERVATION_STATUS } from "../../../../variables/reservations";
import { INTEGRATION_TYPES_VAL } from "../../../../variables/integration-types";

type LockerCodeColumnProps = {
    reservation: any,
    isAdmin: boolean,
    enableDelete: boolean,
    onClickDeletePassword: (reservation: any) => void
};

const NoMaxWidthTooltip = styled(({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {children}
    </Tooltip>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
    },
});

export default function LockerCodeColumnCell({ 
    reservation,
    isAdmin, 
    onClickDeletePassword, 
    enableDelete
}: LockerCodeColumnProps) {
    const allowedStatusToRemovePassword = [RESERVATION_STATUS.Pending, RESERVATION_STATUS.PendingCheckIn];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const createPasswordTooltip = (passwords) => {
        const result = (
            <Container fluid>
                {passwords
                    ? passwords.map((p, i) => (
                          <div key={i}>
                              <div className="d-flex">
                                  <div>Password Id -&nbsp;</div>
                                  <div>{p.passwordId}</div>
                              </div>
                              <div className="d-flex">
                                  <div>Device Id -&nbsp;</div>
                                  <div>{p.deviceId}</div>
                              </div>
                              <div className="d-flex">
                                  <div>Password -&nbsp;</div>
                                  <div>{p.password}</div>
                              </div>
                              <div className="d-flex">
                                  <div>Create Date -&nbsp;</div>
                                  <div>{p.createDate}</div>
                              </div>
                              <div className="d-flex">
                                  <div>Update Date -&nbsp;</div>
                                  <div>{isNotNullOrEmpty(p.updateDate) ? p.updateDate : "-"}</div>
                              </div>
                              <div className="d-flex">
                                  <div>Delete Date -&nbsp;</div>
                                  <div>{isNotNullOrEmpty(p.deletedDate) ? p.deletedDate : "-"}</div>
                              </div>
                              <div className="d-flex">
                                  <div>{isNotNullOrEmpty(p.deletedDate) ? "(DELETED)" : ""}</div>
                              </div>
                              <div className="d-flex">
                                  <div>----</div>
                              </div>
                          </div>
                      ))
                    : null}
            </Container>
        );
        return result;
    };
    return isAdmin === true ? (
        <div>
            <div className="d-flex justify-content-center" style={{ position: "relative" }}>
                <NoMaxWidthTooltip title={createPasswordTooltip(reservation.reservationPasswords)}>
                        <div style={{ marginTop: "4px" }}>{reservation.lockerPassword}</div>
                </NoMaxWidthTooltip>
                {enableDelete === true && reservation.integrationType === INTEGRATION_TYPES_VAL.Track && allowedStatusToRemovePassword.includes(reservation.status) && (
                    <div className="options" style={{ position: "absolute", right: "0",marginLeft: "10px" }}>
                        <IconButton size="small" onClick={handleMenuOpen}>
                            <MoreVert fontSize="small" />
                        </IconButton>
                    </div>
                )}
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        onClickDeletePassword(reservation);
                    }}
                >
                    Reset
                </MenuItem>
            </Menu>
        </div>
    ) : (
        <>{reservation.lockerPassword}</>
    );
}
