import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { isNotNullOrEmpty } from "../../../../util/null-helper";
import 'react-loading-skeleton/dist/skeleton.css';
import { arrayNotNullAndHasEntries } from "../../../../util/null-helper";
import Loader from "../../../../components/Loader/Loader";
import './chart.css';  // Import your custom CSS
import PartnerSelect from "../../../../views/pages/components/PartnerSelect";
import { ApexOptions } from "apexcharts";
import { Box } from "@mui/material";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import { IsAdmin } from "../../../../util/role-helpers";

type GeneralStatisticProps = {
    statistics: Array<any>, 
    partnerId: string, 
    loading: Boolean, 
    partners: Array<any>, 
    onPartnerChange: (partnerId: string) => void, 
    onClearSelection: () => void, 
    role: string
}

export default function GeneralStatistic({ statistics, partnerId, loading, partners, onPartnerChange, onClearSelection, role }: GeneralStatisticProps) {
    const getStatistic = (statistic, mapValue) => {
        return isNotNullOrEmpty(statistic) ? mapValue(statistic) : null;
    }

    const skeletons = [1];

    if (!arrayNotNullAndHasEntries(statistics)) {
        return skeletons.map((x, i) => (
            <Col xl={12} key={i}>
                <Card style={{ minHeight: 600 }}>
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <Col xl={9}>
                                <h6 className="text-light text-uppercase ls-1 mb-3">Statistics</h6>
                            </Col>
                            {arrayNotNullAndHasEntries(partners) && partners.length > 1 ? <Col xl={3} align="left" style={{ position: 'relative'}}>
                                <Box mr={"10px"}>
                                    <PartnerSelect
                                            partners={partners}
                                            onChange={onPartnerChange}
                                            onClearSelection={onClearSelection}
                                            withLoading={true}
                                            enableSelectAll={false}
                                            showPlaceHolder={true}
                                            selectedPartnerId={partnerId}
                                            m={2}
                                        />
                                </Box>
                            </Col> : null}
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="d-flex justify-content-center mb-5 mt-5">
                            { loading === true ? <Loader /> : null}
                            { partnerId === null && loading === false && IsAdmin(role) ? <Box sx={{ fontSize: 16, marginTop: '25px'}}>
                                Please select a <span style={{ color: EasyHubPrimaryColor }}>Partner</span> to display <span style={{ color: EasyHubPrimaryColor }}>Statistics</span>.
                            </Box> : null}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ));
    }

    // Process data for grouped bar chart with two groups per partner name
    const categories = arrayNotNullAndHasEntries(statistics) ? statistics.flatMap((item) => [
        `${getStatistic(item, (statistic) => statistic.partnerName)}`,
    ]) : [];

    const seriesReservations = ['Total Reservations', 
        'Pending Check-In', 
        'Checked-In', 
        'Checked-Out',
        'Total Units', 
        'Active Units', 
        'Inactive Units'
    ].map((metric, index) => ({
        name: metric,
        data: arrayNotNullAndHasEntries(statistics) ? statistics.map((item) => getStatistic(item, (statistic) => [
            statistic.totalReservations,
            statistic.totalReservationsPendingCheckIn,
            statistic.totalReservationsCheckedIn,
            statistic.totalReservationsCheckedOut,
            statistic.totalUnits,
            statistic.totalActiveUnits,
            statistic.totalInactiveUnits
        ][index]) || 0) : []
    }));

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            background: 'transparent',
            stacked: false
        },
        title: {
            text: 'Reservations and Units Statistics',
            align: 'center'
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    colors: '#000'
                }
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false
            }
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        tooltip: {
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            y: {
                formatter: function (val: number) {
                    return val.toString();
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        ]
    };

    return (
        <Col xl="12">
            <Card>
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                        <Col xl={9}>
                            <h6 className="text-light text-uppercase ls-1 mb-3">Statistics</h6>
                        </Col>
                        {arrayNotNullAndHasEntries(partners) && partners.length > 1 ? <Col xl={3} align="left" style={{ position: 'relative'}}>

                            <PartnerSelect
                                partners={partners}
                                onChange={onPartnerChange}
                                onClearSelection={onClearSelection}
                                withLoading={false}
                                enableSelectAll={false}
                                showPlaceHolder={true}
                                selectedPartnerId={partnerId}
                                m={2}
                            />
                        </Col> : null}
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart-container">
                        {loading === false && arrayNotNullAndHasEntries(statistics) ? (
                            <ReactApexChart
                                options={options}
                                series={[...seriesReservations]}
                                type="bar"
                                height={400}
                            />
                        ) : <div className="d-flex justify-content-center mb-5 mt-5" style={ { minHeight: '320px'}}>
                            <Loader />
                        </div>}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
