import { ThreeDots } from "react-loader-spinner";
import { EasyHubPrimaryColor } from "../../variables/colors";

type LoaderProps = {
    height?: number,
    width?: number,
    color?: string
}
export default function Loader({ height, width, color }: LoaderProps){
    return <ThreeDots
        visible={true}
        height={height ? height : 40}
        width={width ? width : 40}
        color={color ? color : EasyHubPrimaryColor}
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
    />
}
