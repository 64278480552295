import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  InputGroup,
  Input,
  Col,
  Row,
} from "reactstrap";

interface ITrackProjectState {
  openedCollapses: Array<any>;
}

interface ITrackProjectProps {
  project: any
}

class TrackProject extends React.Component<ITrackProjectProps, ITrackProjectState> {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: []
    };
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };
  render() {

    const { project } = this.props;

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => this.collapsesToggle("collapseOne")}
              aria-expanded={this.state.openedCollapses.includes(
                "collapseOne"
              )}
            >
              <h5 className="mb-0">{project.ProjectName}</h5>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseOne")}
            >
              <CardBody>
                <Row>
                    <Col xl={12}>
                        {Object.keys(project).map(function(key, i) {
                            return <FormGroup key={`${i}_field_${key}`}>
                            <label className="form-control-label" htmlFor={key}>
                                {key}
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                    id={key}
                                    placeholder={key}
                                    type="text"
                                    value={project[key]}
                                />
                            </InputGroup>
                        </FormGroup>
                        })}
                    </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default TrackProject;