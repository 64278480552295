export const isNullOrEmpty = (value: any) => {
    return value === undefined || value === null || value === '' ? true : undefined;
}   
export const isNotNullOrEmpty = (value: any) => {
    return value !== undefined && value !== null && value !== '' ? true : undefined;
} 

export const arrayNotNullAndHasEntries = (value: any) => {
    return value !== undefined && value !== null && value.length > 0 ? true : undefined;
}   

export const isNotNullOrEmptyShowValueOr = (value: string, or: string) => {
    return value !== undefined && value !== null && value !== '' ? value : or;
} 