import React, { useState } from 'react';
import { Menu, MenuItem, Button, Tooltip } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort'; // Example icon
import './style.css';

export default function OrderByIconButton({ options, selected, onChange }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (id: any) => {
        onChange(id);
        handleClose();
    };

    return (
        <div style={{ display: 'inline-block'}}>
            <Tooltip title="Sort by">
                <Button
                    aria-label="sort-by"
                    aria-controls={open ? 'sort-by-menu' : undefined}
                    aria-haspopup="true"
                    color="primary"
                    variant='outlined'
                    size='small'
                    onClick={handleClick}
                    startIcon={<SortIcon />}
                >
                    Sort by
                </Button>
            </Tooltip>
            <Menu
                id="sort-by-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'sort-by-button',
                }}
            >
                {options.map((item: any) => (
                    <MenuItem
                        key={item.id}
                        selected={item.id === selected}
                        onClick={() => handleMenuItemClick(item.id)}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
