/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from "react";
// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";

import { PartnerContext } from "../../../contexts/partner-context";
import TrackProject from "./cards/TrackProject";
import { useNavigate } from "react-router-dom";
import Units from "./cards/Units";
import { searchForUnits } from "../../../actions/unit-actions";
import Pagination from "../components/Pagination";
import Loader from "../../../components/Loader/Loader";
import { enableUnits } from "../../../actions/unit-actions";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import { useSnack } from "../../../hooks/useSnack";
import { ExpandMore } from "@mui/icons-material";

function PartnerEdit() {
  const { partner } = React.useContext(PartnerContext);
  const [unitSearchResponse, setUnitSearchResponse] = React.useState(null);
  const [loadingUnits, setLoadingUnits] = React.useState(false);
  const snack = useSnack();
  const [pageSize, setPageSize] = React.useState(100);
  const [unitName, setUnitName] = React.useState('');
  const [pageNr, setPageNr] = React.useState(1);
  const [sortBy, setSortBy] = React.useState('+Name');
  const [debouncedValue, setDebouncedValue] = React.useState('');

  const navigate = useNavigate();

  const loadUnits = useCallback(
    async (unitName: string, page: number, pageSize: number, sortBy: string): Promise<void> => {
      setLoadingUnits(true);
      try {
        const response = await searchForUnits(partner.id, unitName, pageSize, page - 1, sortBy);
        setUnitSearchResponse(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUnits(false);
      }
    },
    [partner?.id]
  );

  useEffect(() => {
    if (partner) {
      loadUnits(unitName, pageNr, pageSize, sortBy);
    }
  }, [partner, loadUnits, unitName, pageNr, pageSize, sortBy]);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(unitName);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [unitName]);

  useEffect(() => {
    if (debouncedValue) {
      loadUnits(debouncedValue, 1, pageSize, sortBy);
    }
  }, [debouncedValue, loadUnits, pageSize, sortBy]);

  if (!partner) {
    navigate('/admin/client/settings');
    return <>
    </>
  }

  const handlePageChange = async (e, newPage) => {
    setPageNr(newPage);
    await loadUnits(unitName, newPage, pageSize, sortBy);
  }

  const handleClickSort = async (direction, field) => {
    const newSort = `${direction}${field}`;
    setSortBy(newSort);
    setPageNr(1);
    await loadUnits(unitName, 1, pageSize, newSort);
  }

  const handleUpdateUnit = async (unitId: string, isEnabled: boolean, integrationType: string) => {
    try {
      await enableUnits([unitId], isEnabled, integrationType);
      snack.setMessage("Unit successfully updated");
      snack.onOpen();
    }
    catch(err){
      console.log(err);
      snack.setMessage("Unable to update the unit. Contact support, or try again later.");
      snack.onOpen();
    }
  }

  const handleNameSubmit = async (e) => {
    e.preventDefault();

    await loadUnits(unitName, pageNr, pageSize, sortBy);
  }

  const handleNameChange = (e) => {
    const value = e.target.value;

    setUnitName(value);
  }

  const handlePageSizeChange = async (e) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);

    await loadUnits(unitName, pageNr, newSize, sortBy);
  }

  const getUnitsSectionHeight = (pageSize) => {
    return pageSize === 100 
      ? '500px' 
      : '1000px';
  }

  return (
    <>
      <CardsHeader name="Management" parentName="Clients" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12 d-flex justify-content-center">
            <span className="h1" style={{ color: "#fff" }}>{partner.name}</span>
          </Col>
          <Col xl="12" className="mb-3 mt-3">
            <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="settings-content"
                  id="settings-header"
                >
                    <span className="h2 mb-3">Settings</span>
                </AccordionSummary>
              <AccordionDetails>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={2}>
                        Enabled 
                      </Col>
                      <Col xl={9} >
                        {partner.enabled === true ? "Yes" : "No"}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={2}>
                        Last Updated 
                      </Col>
                      <Col xl={9} >
                        {partner.updateDate}
                      </Col>
                    </Row>
                    <br />
                    <CardTitle className="h3 mb-4">Project Settings</CardTitle>

                    <Row>
                      {Object.keys(partner.settings).map(function(key) {
                        return <Col xl={12} key={`project_${key}`}>
                          <TrackProject project={partner.settings[key]} />
                        </Col>
                      })}
                    </Row>
                  </CardBody>
              </Card>
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col xl="12">
          <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="units-content"
                  id="units-header"
                >
                    <div className="h2 mb-3">
                      <div style={{ height: '40px'}}>Units</div>       
                      <div className="ml-3">
                      </div>     
                    </div>
                </AccordionSummary>
              <AccordionDetails>
                <Card>
                  <CardBody>
                  <Row className="mb-3">
                      <Col xl={4}>
                        <Form role="form" onSubmit={handleNameSubmit}>
                          <FormGroup style={{ marginTop: '8px' }}>
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fa fa-search" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Unit Name"
                                  type="text"
                                  value={unitName}
                                  onChange={handleNameChange}
                                />
                              </InputGroup>
                          </FormGroup>
                        </Form>
                      </Col>
                      <Col xl={1}>
                        <FormControl fullWidth>
                          <InputLabel id="page-size-filter">Page Size</InputLabel>
                          <Select
                            labelId="page-size-filter"
                            id="page-size-select"
                            value={pageSize}
                            label="Page Size"
                            onChange={handlePageSizeChange}
                          >
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                            <MenuItem value={300}>300</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col xl={1}>
                      {loadingUnits === true ? <Loader /> : null}
                      </Col>
                      <Col xl={6}>
                        {unitSearchResponse ? <Pagination 
                          totalPages={unitSearchResponse.totalPages}
                          onChange={handlePageChange}
                          page={pageNr}
                          float={'right'}
                        /> : null}
                      </Col>
                      <Col xl={12}>
                          <span>{unitSearchResponse ? unitSearchResponse.totalCount : 0} items found.</span>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xl={12} style={{ maxHeight: getUnitsSectionHeight(pageSize), overflowY: 'auto'}} className="mb-3">
                        <Units 
                          units={unitSearchResponse ? unitSearchResponse.items : []}
                          handleClickSort={handleClickSort}
                          handleUpdateUnit={handleUpdateUnit}
                          sortBy={sortBy}
                        />
                      </Col>
                      <Col xl={12}>
                        {unitSearchResponse ? <Pagination 
                          totalPages={unitSearchResponse.totalPages}
                          onChange={handlePageChange}
                          page={pageNr}
                        /> : null}
                      </Col>
                      <Col xl={12}>
                          <div className="ml-3 d-flex justify-content-center" style={{ minHeight: '40px' }}>
                            {loadingUnits === true ? <Loader /> : null}
                        </div> 
                      </Col>
                  </Row>
                  </CardBody>
              </Card>
              </AccordionDetails>
            </Accordion>
            
            <Snackbar
              open={snack.isOpen}
              autoHideDuration={6000}
              onClose={snack.onClose}
              message={snack.message}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerEdit;
