import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";


export default function Enabler({ active, onChange, unitId, integrationType }){
    const label = active === true ? "Yes" : "No";
    const [checked, setChecked] = useState(active);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        onChange(unitId, event.target.checked, integrationType);
    }

    return <FormControlLabel control={<Switch checked={checked} onChange={handleChange} color="primary" />} label={label} />
}