import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

type TopDrawerProps = {
    isOpen: boolean,
    onOpen: (open: any) => void,
    title: string, 
    children: any
}

export default function TopDrawer({ title, children, isOpen, onOpen }: TopDrawerProps) {

    const anchor = 'top';

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      onOpen(open);
};

  return (
    <>
        {isOpen === true ? <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(true)}>{title}</Button>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={toggleDrawer(false)}
          >
            {children}
          </Drawer>
        </React.Fragment> : null}
    </>
  );
}
