import { FileCopy } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Collapse,
  Col,
  Row,
  Table,
  Container,
} from "reactstrap";
import './style.css';
import SortButton from "../../../../components/Headers/SortButton";
import Loader from "../../../../components/Loader/Loader";

interface IUsersTableState {
  openedCollapses: Array<any>;
}

interface IUsersTableProps {
  users: Array<any>,
  pageSize: number, 
  sortBy: string, 
  isLoading: boolean,
  tableLegend?: string,
  handlePageSizeChange: (e: SelectChangeEvent) => void,
  handleClickSort: (direction: string, column: string) => void
}

class UsersTable extends React.Component<IUsersTableProps, IUsersTableState> {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: ["collapseOne"]
    };
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  render() {

    const { 
      users, 
      pageSize, 
      sortBy, 
      isLoading, 
      handlePageSizeChange,
      handleClickSort 
    } = this.props;

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseOne")}
            >
              <CardBody>
                <Container fluid>
                  <Row>
                      <Col xl={12}>
                        {isLoading === true ?<div className="d-flex justify-content-center" style={{ position: 'absolute', left: 0, right: 0, top: '40%'}}><Loader /> </div> : null}
                          <Table className="align-items-center table-flush" responsive>
                              <thead className="thead-light">
                                  <tr>
                                      <th scope="col">
                                          Id <SortButton column="Id" selected={sortBy} onClick={(direction) => handleClickSort(direction, 'Id')} />
                                      </th>
                                      <th scope="col">
                                          Name <SortButton column="FirstName" selected={sortBy} onClick={(direction) => handleClickSort(direction, 'FirstName')} />
                                      </th>
                                      <th scope="col">
                                          Email <SortButton column="Username" selected={sortBy} onClick={(direction) => handleClickSort(direction, 'Username')} />
                                      </th>
                                      <th scope="col">
                                        Role <SortButton column="Role" selected={sortBy} onClick={(direction) => handleClickSort(direction, 'Role')} />
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {users ? users.map((i) => {
                                      return <tr key={`unit_${i.id}`}>
                                          <th scope="row">
                                              <Tooltip title={i.id}>
                                                  <div className="hover" onClick={() => {navigator.clipboard.writeText(i.id)}}>
                                                      <FileCopy />
                                                  </div>
                                              </Tooltip>
                                          </th>
                                          <td>{i.firstName} {i.lastName}</td>
                                          <td>{i.username}</td>
                                          <td>{i.role}</td>
                                      </tr>
                                  }) : null}
                              </tbody>
                          </Table>
                      </Col>
                      <Col>
                      <Col xl={{ size: 2, offset: 10}} >
                          <FormControl sx={{ m: 2, width: '100%' }}>
                              <InputLabel id="page-size-filter">Items per page</InputLabel>
                              <Select
                                  labelId="page-size-filter"
                                  id="page-size-select"
                                  value={pageSize.toString()}
                                  label="Page Size"
                                  onChange={handlePageSizeChange}
                              >
                                  <MenuItem value={5}>5</MenuItem>
                                  <MenuItem value={10}>10</MenuItem>
                                  <MenuItem value={50}>25</MenuItem>
                                  <MenuItem value={100}>100</MenuItem>
                                  <MenuItem value={200}>200</MenuItem>
                                  <MenuItem value={300}>300</MenuItem>
                              </Select>
                          </FormControl>
                      </Col>
                      </Col>
                  </Row>
                </Container>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default UsersTable;