import './SortButton.css';

export default function SortButton({ onClick, column, selected }){
    const selectedDirection = selected.substring(0, 1);
    const selectedColumn = selected.substring(1, selected.length);

    const isSelected = selectedColumn === column;

    const handleClick = () => {
        const newDirection = selectedDirection === "-" ? '+' : '-';
        onClick(newDirection);
    }

    return <i 
        className={`fa fa-arrow-${isSelected === true && selectedDirection === "-" ? "up" : "down"} sort-button`} 
        onClick={handleClick} 
    />
}